f.indicators:after {
  display: none;
  content: url("../images/svg/overall_w.svg") url("../images/svg/covid_w.svg") url("../images/svg/health_w.svg") url("../images/svg/economic2_w.svg") url("../images/svg/port_w.svg") url("../images/svg/transport_w.svg") url("../images/svg/sector_w.svg") url("../images/svg/stock_w.svg") url("../images/svg/access_w.svg") url("../images/svg/stock_w.svg") url("../images/svg/fertilizer_price_w.svg") url("../images/svg/transit_w.svg");


}

.indicators {
  background-color: #f7f7f7;
  display: flex;
  width: 100%;
  justify-content: center;
  border-top: 1px solid #d5d5d5;


  .group {
    padding: 0 1vw;
    border-right: 1px solid #d5d5d5;
    padding-bottom: 5px !important;
    margin: 7px 0 !important;

    .header {
      height: 25px;
      font-size: 14px;
      color: #34610c;
      font-weight: bold;
      text-align: center;
    }

    .list {

      display: flex;
      flex-direction: row;
      padding: 0.5vw;


    }


    .indicator-button.active {
      background-color: #73a745 !important;

      .indicator-label {
        color: #FFF;
      }

      .icon.overall_impact {
        background-image: url("../images/svg/overall_w.svg");
      }

      .icon.covid {
        background-image: url("../images/svg/covid_w.svg");
      }

      .icon.health_measures {
        background-image: url("../images/svg/health_w.svg");
      }

      .icon.economic_measures {
        background-image: url("../images/svg/economic2_w.svg");
      }

      .icon.ports {
        background-image: url("../images/svg/port_w.svg");
      }

      .icon.flow {
        background-image: url("../images/svg/transport_w.svg");
      }

      .icon.response {
        background-image: url("../images/svg/sector_w.svg");
      }

      .icon.affordability {
        background-image: url("../images/svg/stock_w.svg");
      }

      .icon.exposure{
        background-image: url("../images/svg/conflict_w.svg");
      }
      .icon.allocations {
        background-image: url("../images/svg/allocation_w.svg");
      }

      .icon.availability {
        background-image: url("../images/svg/stock_w.svg");
      }

      .icon.affordability {
        background-image: url("../images/svg/fertilizer_price_w.svg");
      }

      .icon.transit,
      .icon.transit_borders {
        background-image: url("../images/svg/transit_w.svg");
      }

    }

    .indicator-button {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      padding: .6vw;
      width: 11vw;
      margin: 0px .2vw;
      min-height: 62px;
      border-radius: 4px;
      background-color: #e7e7e7;
      border: 1px solid #e0e0e0;
      cursor: pointer;
      padding-bottom: 8px;


      .icon {
        background-repeat: no-repeat;
        margin: 2px;
        height: 23px;
        width: 23px;
      }

      .indicator-label {
        text-align: center;
        font-size: 12px;
        height: 28px;
        line-height: 12px;
        width: 100px;

        font-weight: 600;
        padding: 2px;
        color: #7c7c7c;
      }

      .indicator-label.exposure {
        width: 130px;

      }
    }
  }

  .group:nth-last-child(1) {
    border-right: 0px;
  }


}


.icon.overall_impact {
  background-image: url("../images/svg/overall.svg");
}

.icon.covid {
  background-image: url("../images/svg/covid.svg");
}

.icon.health_measures {
  background-image: url("../images/svg/health.svg");
}

.icon.economic_measures {
  background-image: url("../images/svg/economic2.svg");
}

.icon.ports {
  background-image: url("../images/svg/port.svg");
}

.icon.flow {
  background-image: url("../images/svg/transport.svg");
}

.icon.response {
  background-image: url("../images/svg/sector.svg");
}

.icon.availability {
  background-image: url("../images/svg/stock.svg");
}

.icon.exposure{
  background-image: url("../images/svg/conflict.svg");
}

.icon.allocations {
  background-image: url("../images/svg/allocation.svg");
}

.icon.affordability {
  background-image: url("../images/svg/fertilizer_price.svg");
}

.icon.transit_borders,
.icon.transit {
  background-image: url("../images/svg/transit.svg");
}
