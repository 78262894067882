
/* Main column width */
.editor-post-title__block .editor-post-title__input {
    font-family: 'Nunito Sans' !important;
    color: #558A10 !important;
}

.wp-block {
    font-family: 'Nunito Sans' !important;
    font-weight: 300;
    max-width: 1024px;

}

a,a:hover {
    color:#5B9E20;
}


/* Width of "wide" blocks */
.wp-block[data-align="wide"] {
    max-width: 1366px;
}

/* Width of "full-wide" blocks */
.wp-block[data-align="full"] {
    max-width: none;
}

h1,h2,h3,h4,h5,h6{
  color: #558A10 !important;
}
