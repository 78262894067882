
.ui.centered.stackable.padded.grid.footer{
	background: #f4f3f3;
	.column {
		padding: 0 0 74px 8vw !important;
	}
	img {
		height: 42px;
	}
	.contact-us {
		padding-left: 15vw !important;
		.label {
			padding-bottom: 15px !important;
		}
		.afo-email {
			color: #34610c;
		}
	}
	.segments {
		margin: 0px !important;
	    border: unset !important;
	    box-shadow: unset !important;
	}
	.segment {
		padding: 0px !important;
		background: #f4f3f3 !important;
		border: unset !important;
		text-align: left;
		padding-left: 20px;
	}
	.label {
		font-size: 14px;
		padding: 35px;
		color: #34610c;
		font-weight: 600;
		text-transform: uppercase;
		background: none;
		padding-left: 0px;		
	}
	.menu {
		padding-left: 7vw;
		.column {
			padding: 0px !important;
		}
		height: 60px;
		width: 100%;
		border-bottom: unset;
		border-left: unset;
		border-right: unset;
		background: none !important;
		box-shadow: unset !important;
		a {
			color: #34610c !important;
			font-size: 13px !important;
			line-height: 35px;		
			font-weight: 400 !important;
		}
	}
	.developer-logo {
		color: #34610c;
		display: flex;
    	line-height: 13px;
    	a {
    		height: 0px;
    	}
    	div {
    		padding-top: 25px;
    	}
    	img {
			height: 35px;
			margin-left: 10px;
			margin-top: 12px;

    	}
	}
}