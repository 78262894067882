
.header-content {
	display: unset !important;
	>div {
		padding: unset !important;
		.ui.sticky {
			z-index: 10000;
		}
	}
}

.ui.sticky {
    z-index: unset;
}

.ui.menu.head {
	height: 68px;
	margin: 0 !important;
	background: #73a745;
	border: none;
	border-radius: 0px;
	.item.logo {
		padding-top: 10px;
		width: 70px;

		img {
			padding: 0;
			margin: 0;
			height: 45px !important;
			width: 45px !important;
		}
	}

	.item:before {
		top: 10px !important;
		height: 40px !important;
		bottom: 10px !important;
	}

	.item.logo:before {
		display: none !important;
	}

	.item.title {

		font-size: 17px;
		color: #ffffff;
		font-weight: bold;
		padding: 17px 0 0;
		flex-direction: column;
		flex-grow: 1;

		div {
			display: block;
			width: 100%;
			text-align: left;
		}

		.app-sub-title {
			margin-top: 6px;
			font-size: 12px;
			color: #d2faaf;
			padding: 0;
		}
	}

	.ui.selection.active.dropdown {}

	.ui.selection.active.dropdown .menu {
		border: 1px solid #d4d4d5;
		border-radius: 5px;
		border-radius: 0.28571429rem;
		-webkit-box-shadow: 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15);
		box-shadow: 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15);
	}

	.item.date {
		min-width: 170px; //less that 170 and septembre will not fit
		padding: 0;
		.ui.selection.visible.dropdown > .text:not(.default) {
			font-weight: bold;
			color: rgba(0,0,0,.8);
		}

		.dropdown {
			margin: 2px;
			background-color: #73a745 !important;
			border: 0;
			padding: 0;
			color: #FFF;

			> .text {
				padding-left: 10px;
				padding-top: 12px;
				color: #FFF !important;
				font-size: 16px;
				font-weight: bold;
			}

			.menu {
				border: 1px solid #000;
				border-radius: 5px;
				padding: 5px;

				.text {
					padding: 3 3 3 0;
					margin: 0;
					color: #000 !important;
					font-size: 14px;
				}

				.item {
					padding: 10px !important;
					border: 0;
				}

				.item.active {
					padding: 10px !important;
					border: 0;
					background-color: #FFF !important;
				}
			}
		}
	}

	.item.country {
		min-width: 200px;
		padding: 2px;
		width: 15vw;
		.selected-label {
			padding-left: 10px;
			color: #FFF !important;
			font-size: 16px;
			font-weight: bold !important;
		}

		.icon {
			float: right;
			margin-right: 10px;
			color: #FFF !important;
			font-size: 12px;
			opacity: 0.8;
		}
	}

	.item.indicator {
		clear: both;
		display: block !important;
		float: none;
		position: relative;
		border: 1px solid red;
	}

	.item.wp {
		min-width: 473px;
		padding: 0;
		width: auto;

		.menu {
			display: flex;
			.item{
				flex-grow: 1;
				width: auto;
				padding: 5px .6em 5px .6em!important;
			}
			background: #73a745;
		}

		.menu .item {
			a {
				color: #d2faaf !important;
			}
		}
	}

	.item.lan {
		align-items: flex-end;
		width: 90px;
		padding: 0;
		align-items: center;
		justify-content: center;
	}

	.item.lan:before {
		display: none !important;
	}

	.lang-selector {
		width: 56px;
		height: 26px;

		.selected {
			background-color: #8eca59;
			color: #ffffff !important;
		}

		button {
			padding: 0;
			width: 50%;
			background-color: #c9c9c9;
			font-size: 12px !important;
			color: #a1a0a0;
		}

		a {
			color: unset;
		}
	}
}
