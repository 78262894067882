html {
  height: 100%;
}

.green {
  background-color: #34610c!important;
}

.head {
  background-color: #73a745;
  color: #FFF;
}

.indicators.active {
  background-color: #73a745;
}

.language.active {
  background-color: #f2f1f0;
}

.title.green {
  color: #34610c;
}




.ui.popup {
z-index: 10500 !important;
  border-radius: 4px;
  background-color: #EEE !important;
  color: #585858;
  font-size: 14px;
  min-width: 330px !important;
  overflow: visible;
  .indicator-description {

      .title {
        font-weight: bold;
        padding: 3px 0px;
        border-radius: 3px;
        margin-bottom: 5px !important;
      }
      p {
        padding: 10px;
      }
    }
}
.ui.popup:before {
    margin-left: auto!important;
    background: #EEE !important;
    
}
.high {
  fill: #e46868;
  background-color: #e46868 !important;
}


.low-or-none,
.low,
.stable-or-decrease,
.covid-stable-or-decrease,
.food-crop{
  fill: #95c46c;
  background-color: #95c46c !important;
}

.text.dashboard-link{
  fill: none;
  color: #eace1d;
  background-color: transparent !important;
}

.text.low,
.text.low-or-none,
.text.stable-or-decrease,
.text.covid-stable-or-decrease,
.text.food-crop{
  fill: none;
  color: #95c46c;
  background-color: transparent !important;
}

.increase-of-25,
.covid-increase-of-less-50,
.limited,
.food--cash-crop
{
  fill: #fbdf42;
  background-color: #fbdf42 !important;
}

.text.increase-of-25,
.text.covid-increase-of-less-50,
.text.limited,
.text.food--cash-crop
{
  fill: none;
  color: #fbdf42;
  background-color: transparent !important;
}

.increase-of-2550,
.covid-increase-of-50100,
.moderate,
.cash-crop
{
  fill: #f5a05b;
  background-color: #f5a05b !important;
}


.text.increase-of-2550,
.text.covid-increase-of-50100,
.text.moderate,
.text.cash-crop{
  fill: none;
  color: #f5a05b;
  background-color: transparent !important;
}


.increase-of-50,
.text.high,
.covid-increase-of-100,
.strong {
  fill: #e46868;
  background-color: #e46868 !important;
}

.text.high,
.text.increase-of-50,
.text.covid-increase-of-100,
.text.strong {
  fill: none;
  color: #e46868;
  background-color: transparent !important;
}


.no-data, .no-data-a,.no-data-b {
  fill: #CCC;
  background-color: #CCC !important;
}

.text.no-data, .text.no-data-a {
  fill: none;
  color: #CCC;
  background-color: transparent !important;
}

.map {
  background-color: #dff1ff;
}

.content {
  background-color: #34610c;
}

.dropdown.time,
.dropdown.time {
  text-transform: capitalize;

  .item {
    text-transform: capitalize !important;
  }
}


.wp.page{
  padding: 40px;
}





.low-or-none.inverted {
  background-color: #e46868 !important;
  fill: #e46868 !important;
  color: #e46868 !important;
}

.limited.inverted {
  background-color: #f5a05b !important;
  fill: #f5a05b !important;
  color: #f5a05b !important;

}
.moderate.inverted {
  background-color: #fbdf42 !important;
  fill: #fbdf42 !important;
  color: #fbdf42 !important;
}

.strong.inverted {
  fill: #95c46c !important;
  background-color: #95c46c !important;
  color: #95c46c !important;
}

.text.inverted{
  background-color: transparent!important;
}