.expanded {
	background: #d2e4c2;
	color: #7c7c7c;

	.dropdown {
		background: #d2e4c2 !important;
	}
}
.location-dropdown {
	min-width: 1600px!important;;

	z-index: 10001 !important;
}
.location-selected {
	cursor: pointer;
	width: 100%;
	border-radius: 3px;

	> div {
		display: inline-block;
	}

}


.location-selected:hover {
	/*font-weight: bold;
	  	border: 1px solid #d2e4c2;*/
}

.locations-list {

	width: 500px !important;

	.left.aligned.column {
		padding: 15px !important;

	}
	.region-box {
		width: 50%;
	}

	.region-item {
		color: #000 !important;

		cursor: pointer;
		font-weight: bold;
	}

	.region-item:hover {
		color: #4c4c4c;
	}

	.country-item {
		cursor: pointer;
		//float: left;
		position: relative;
		//width: 50%;
	}

	.country-item:hover {
		font-weight: bold;
	}
}
