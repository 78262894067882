


body {
  margin: 0;
  font-family: 'Nunito Sans' !important;
  font-weight: 300;
  font-stretch: ultra-expanded;
  font-size: 1em;
  color:#000;
  height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

b, strong {
  font-weight: 800 !important;
}

strong{
    font-weight: 800 !important;
}
h2{
  color: #000;
}




.ui {
   font-family: 'Nunito Sans' !important;
}



.ui.menu .ui.dropdown .menu>.item {
    margin: 0;
    text-align: left;
    font-size: 1em!important;
    padding: .78571429em 1.14285714em!important;
    background: 0 0!important;
    color: rgba(0,0,0,.87)!important;
    text-transform: none!important;
    font-weight: 400!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    -webkit-transition: none!important;
    transition: none!important;
}

.ui.menu .ui.dropdown .menu>.item:hover {
    margin: 0;
    background-color: #FFF!important;
    color: rgba(0,0,0,.87)!important;
    text-transform: none!important;
    font-weight: bold!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    -webkit-transition: none!important;
    transition: none!important;
    .text{
    font-weight: bold !important;
}
}
